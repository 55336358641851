<div class="pad-20">
  <div class="modal-header flex justify-between items-center">
    <h4 class="modal-title">{{emsShallowEvent.name}} - {{emsShallowEvent.payloadType}}</h4>
    <button type="button" class="close x-btn x-btn-primary" aria-label="Close" (click)="dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="overlay" *ngIf="loadingEvent">
      <div class="spinner-wrapper">
        <app-enelx-load></app-enelx-load>
      </div>
    </div>
    <div class="row" [class.blurred]="loadingEvent">
      <pre>{{payload}}</pre>
    </div>
  </div>
  <div class="modal-footer">

  </div>
</div>

