import { Configuration, Location, Username } from "@model";
import { TreeNode } from "tree/dist/tree/lib/models";

export interface Site {
  id: string;
  parentId: string;
  name: string;
  displayLabels: Record<string, string>;
  description: string;
  descriptions: Record<string, string>;
  deviceId: string;
  sid: string,
  status: string;
  timezone: string;
  locale: string;
  children: TreeNode<any>[];
  createdBy: Username;
  createdDate: string;
  lastUpdatedDate: string;
  settings: Location;
  controlMode: string;
  derStatus: string;
  spaceType: string;
  accountId: string;
  config: Configuration;
}

export class Site {
  constructor() {
    this.id = "";
    this.parentId = "";
    this.name = "";
    this.displayLabels = {};
    this.description = "";
    this.descriptions = {};
    this.deviceId = "";
    this.status = "";
    this.timezone = "";
    this.locale = "";
    this.children = new Array<TreeNode<any>>();
    this.createdBy = {} as any as Username;
    this.createdDate = "";
    this.lastUpdatedDate = "";
    this.settings = new Location();
    this.controlMode = "";
    this.derStatus = "";
    this.spaceType = "Site";
    this.accountId = "";
    this.config = {} as any;
  }
}
