import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { SiteHomeComponent } from "src/app/view/site-home/site-home.component";
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [SiteHomeComponent],
  exports: [SiteHomeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSortModule,
    MatRippleModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
})
export class SiteHomeModule {
}
