import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Crons, ProgramConfig, Site } from '@model';
import { TreeService } from '@service';
import { FormControl, FormGroup } from '@angular/forms';

enum Modes {
  details = 'details',
  configure = 'configure',
  events = 'events',
}

@Component({
  selector: 'bmd-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit, OnDestroy {
  tabsEnum: typeof Modes = Modes;
  mode: Modes = Modes.details;
  site: Site;
  apiKey: string;
  crons: Crons = new Crons();
  saving = false;
  siteForm: FormGroup;
  programConfig: ProgramConfig;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private treeService: TreeService,
  ) {
    this.siteForm = new FormGroup({
      operationCostPerMWh: new FormControl(),
      maxRegupMw: new FormControl(),
      maxRegdownMw: new FormControl(),
      maxRrsMw: new FormControl(),
      maxEcrsMw: new FormControl(),
    });
  }

  public get Modes() {
    return Modes;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.subscribe(data => {
        const siteObs: Observable<Site> = data['data'][0];
        const crons: Crons = data['data'][1];
        siteObs.subscribe(site => {
          console.log('loaded site');
          this.site = site;
          this.programConfig = data['data'][2];
          this.siteForm.patchValue(site.config);

          this.treeService.selectSiteInList(site);
          if (!site.deviceId) {
            return;
          }
        });

        this.crons = crons;
      }),

      this.route.queryParams.subscribe(params => {
        const tab = params['tab'];
        this.mode = tab === Modes.details.toString() || tab === Modes.events.toString() ? Modes[tab] : Modes.configure;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  changeMode(mode: Modes): void {
    this.mode = mode;
    this.appendModeToQueryParam(mode);
  }

  private appendModeToQueryParam(mode: Modes): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: mode,
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true,
      // do not trigger navigation
    });
  }
}
