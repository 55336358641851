<div class="container pad-20" *ngIf="theForm">
    <form [formGroup]="dynamicForm" (ngSubmit)="updateEntity()">
        <div class="modal-header flex justify-between items-center">
            <h2 class="modal-title">{{ headerText }}</h2>
            <button
                type="button"
                class="x-btn x-btn-secondary"
                title="Paste from Clipboard"
                (click)="processClipboard()"
            >
                <mat-icon class="bi bi-clipboard-data">content_paste</mat-icon>
            </button>
        </div>
        <hr />
        <div class="modal-note text-light-color">{{ subHeaderText }}</div>
        <br />
        <div class="modal-body" mat-dialog-content>
            <table class="table">
                <thead>
                    <tr class="text-primary-color">
                        <th scope="col">Hour Ending</th>
                        <ng-container *ngFor="let fieldLabel of fieldLabels">
                            <th scope="col">{{ fieldLabel }}</th>
                        </ng-container>
                        <th scope="col">Bulk Clone</th>
                    </tr>
                </thead>

                <tbody formArrayName="theForm">
                    <ng-container
                        *ngFor="let formField of theForm?.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <tr class="table-primary">
                            <td>
                                HE
                                <ng-container *ngIf="i !== 24">{{ i + 1 }}</ng-container>
                                <ng-container *ngIf="i === 24">2*</ng-container>
                            </td>
                            <ng-container *ngFor="let fieldName of fieldNames">
                                <td style="padding-left:0;">
                                    <input
                                        type="number"
                                        class="form-control"
                                        [formControlName]="fieldName"
                                        [value]="this.fieldsToUpdate[fieldName]"
                                        [title]="fieldName"
                                    />
                                </td>
                            </ng-container>
                            <td class="flex items-center justify-between text-primary-color">
                                <button
                                    id="clone_{{ i }}"
                                    type="button"
                                    class="x-btn x-btn-secondary small-btn"
                                    title="Copy this row everywhere"
                                    matTooltip="Copy this row everywhere"
                                    (click)="cloneRowEverywhere(i)"
                                >
                                    <mat-icon>expand_all</mat-icon>
                                </button>
                                <button
                                    id="clone_up_{{ i }}"
                                    type="button"
                                    class="x-btn x-btn-secondary small-btn"
                                    title="Copy this row to all the previous rows"
                                    matTooltip="Copy this row to all the previous rows"
                                    (click)="cloneRowUp(i)"
                                    [disabled]="i === 0"
                                >
                                    <mat-icon>move_up</mat-icon>
                                </button>
                                <button
                                    id="clone_down_{{ i }}"
                                    type="button"
                                    class="x-btn x-btn-secondary small-btn"
                                    title="Copy this row to all the following rows"
                                    matTooltip="Copy this row to all the following rows"
                                    (click)="cloneRowDown(i)"
                                    [disabled]="i === 24"
                                >
                                    <mat-icon>move_down</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="modal-footer flex justify-end items-center">
            <div *ngIf="showError" class="flex-grow-1 {{ saveDialogColor }}">
                <i class="bi {{ saveDialogIcon }}">&nbsp;</i>{{ saveDialogMessage }}
            </div>
            <button type="button" class="x-btn x-btn-secondary" type="submit" [disabled]="this.dynamicForm?.invalid">
                OK
            </button>
            <button type="button" class="x-btn x-btn-secondary" (click)="cancelForm()">Cancel</button>
        </div>
    </form>
</div>
