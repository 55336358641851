import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import { User } from 'global-nav';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly userEmitter = new Subject<User>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getUser(): Observable<User> {
    return this.resourceService.get('user/details');
  }
}
