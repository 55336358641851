import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { detectBrowser } from '@helper';
import { Site } from '@model';
import { GlobalAlertService, SiteService, TimezonesService } from '@service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bmd-site-home',
  templateUrl: './site-home.html',
  styleUrls: ['./site-home.component.scss'],
})
export class SiteHomeComponent implements OnChanges {
  @Input() site: Site;
  @Input() siteForm: FormGroup;
  lastRefreshed: string;
  isFirefox: boolean;

  constructor(
    private timezoneService: TimezonesService,
    private translateService: TranslateService,
    private siteService: SiteService,
    private alertService: GlobalAlertService,
  ) {
    this.isFirefox = detectBrowser().includes('Firefox');
  }

  public get operationCostPerMWh() {
    return this.siteForm.get('operationCostPerMWh') as FormControl;
  }

  public get maxRegupMw() {
    return this.siteForm.get('maxRegupMw') as FormControl;
  }

  public get maxRegdownMw() {
    return this.siteForm.get('maxRegdownMw') as FormControl;
  }

  public get maxRrsMw() {
    return this.siteForm.get('maxRrsMw') as FormControl;
  }

  public get maxEcrsMw() {
    return this.siteForm.get('maxEcrsMw') as FormControl;
  }

  ngOnChanges(): void {
    this.getLastSave();
  }

  getLastSave(): void {
    this.lastRefreshed = this.timezoneService.getDateTimeDisplayLabel(this.site?.parentId, this.site?.lastUpdatedDate);
    if (this.lastRefreshed === '') {
      this.lastRefreshed = this.translateService.instant('last.refreshed.never');
    }
  }

  saveSite() {
    this.site.config.operationCostPerMWh = this.operationCostPerMWh.value;
    this.site.config.maxRegupMw = this.maxRegupMw.value;
    this.site.config.maxRegdownMw = this.maxRegdownMw.value;
    this.site.config.maxRrsMw = this.maxRrsMw.value;
    this.site.config.maxEcrsMw = this.maxEcrsMw.value;

    this.siteService.postSiteConfig$(this.site.id, this.site.config).subscribe(resp => {
      this.alertService.setSuccess('Site configuration has been saved successfully!');
    });
  }

  updateRtForecastSource() {}
}
