import { Environment } from "./environment.interface";

export { Environment } from "./environment.interface";

export const environment: Environment = {
  production: false,
  brands: {
    desco: {
      baseUrl: "/"
    }
  },
  defaults: {
    brand: "desco",
    locale: "en-us"
  },
  apiKey: ""
};
