import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guard";
import { PermissionEnum } from "@model";
import { BMDModule, HomeComponent, SiteComponent } from "@view";
import { SiteResolver } from "@resolver";

enum Routing {
  doc = "doc",
  list = "list",
  root = "",
  viewSite = "details/:siteId/view-site",
}

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: "reload",
  enableTracing: false
};

const routes: Routes = [
  {
    path: Routing.viewSite,
    component: SiteComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.update] },
    resolve: { data: SiteResolver }
  },
  {
    path: Routing.root,
    component: HomeComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read] }
  },
  {
    path: Routing.doc,
    redirectTo: "/doc/index.html"
  },
  {
    path: "**",
    redirectTo: "/"
  }
];

@NgModule({
  imports: [
    BMDModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule],
  providers: [AuthGuard, SiteResolver]
})
export class CoreRoutingModule {
}
