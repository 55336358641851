import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import { Application } from 'global-nav';
import { ResourceService } from 'src/app/core/service/resource.service';
import { toCamel } from 'convert-keys';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private readonly applicationEmitter = new Subject<Application>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getApplications(): Observable<Application[]> {
    return of(toCamel(
      [{
        id: 'app 1',
        sectionId: 1,
        displayLabel: 'test app 1',
        url: 'test_url.com',
      },{
        id: 'app 2',
        sectionId: 2,
        displayLabel: 'test app 2',
        url: 'test_url.com',
      },{
        id: 'app 3',
        sectionId: 3,
        displayLabel: 'test app 3',
        url: 'test_url.com',
      }]
    ));
  }
}
